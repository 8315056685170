import React, { useEffect, useState } from 'react'
import {ListGroup} from 'react-bootstrap'
import {useAuth0} from '@auth0/auth0-react'
import styles from './SrmList.module.scss'
import SrmRow from './components/SrmRow'
import {
  Srm
} from '../../models'
import {
  getSrms
} from '../../api/client'

interface Props {
  onSrmSelect: (srm: Srm) => void
  timezone: string
}

const SrmList = (props: Props) => {
  const { getIdTokenClaims } = useAuth0()
  const [srms, setSrms] = useState([])
  const [filterText, setFilterText] = useState("")
  const [selectedSrm, setSelectedSrm] = useState<Srm | null>(null)

  let filteredSrms = srms
  if (filterText.length > 0) {
    filteredSrms = srms.filter((srm) => {
      return srm.serialNumber.toLowerCase().includes(filterText.toLowerCase())
    })
  }

  useEffect(() => {
    (async () => {
      try {
        const claims = await getIdTokenClaims();
        getSrms("", claims.__raw).then((srms: Srm[]) => {
          let hash = {}
          srms.forEach((srm: Srm) => {
            if (hash[srm.serialNumber] === undefined) {
              hash[srm.serialNumber] = srm
            } else {
              let current: Srm = hash[srm.serialNumber]
              if (current.date < srm.date) {
                hash[srm.serialNumber] = srm
              }
            }
          })
          setSrms(Object.keys(hash).map((key) => hash[key]))
        })
      } catch (error) {
        console.error(error)
      }
    })()
  }, [getIdTokenClaims])

 return (
   <div className={styles.content}>
     <form>
        <input
          id={styles.filterSrms}
          type="text"
          placeholder="Filter by serial number"
          onChange={(e) => {setFilterText(e.target.value)}} />
      </form>
     <ListGroup>
       {filteredSrms.map((srm, index) => {
         let selected = srm === selectedSrm
         return (
           <ListGroup.Item
             active={selected}
             className={selected ? styles.selectedRow : styles.unselectedRow}
             action
             onClick={() => {
               props.onSrmSelect(srm)
               setSelectedSrm(srm)
             }}
             key={index}
             as="li"
            >
             <SrmRow timezone={props.timezone} srm={srm} selected={selected} />
           </ListGroup.Item>
         )
       })}
     </ListGroup>
    </div>
 )
}

export default SrmList