import React from 'react'

import styles from './Navbar.module.scss'

import LoginButton from '../auth/LoginButton'
import LogoutButton from '../auth/LogoutButton'
import TimezoneSelect from "react-timezone-select"

interface Props {
  isAuthenticated: boolean
  onLogoutPressed: () => void
  selectedTimezone: string
  onTimezoneChanged: (string) => void
}

const Navbar = (props: Props) => {

  return (
    <div className={styles.content}>
      <img id={styles.logo} src="/cc-icon.png" alt="Command Center Cloud" />
      {!props.isAuthenticated &&
        <div className={styles.login}>
          <LoginButton />
        </div>
      }
      {props.isAuthenticated &&
        <div className={styles.navControls}>
        <TimezoneSelect
          className={styles.timezone}
          value={props.selectedTimezone}
          onChange={(timezone) => {
            props.onTimezoneChanged(timezone.value)
          }}
        />
        <div className={styles.login}>
          <LogoutButton />
        </div>
        </div>
      }
    </div>
  )
}

export default Navbar