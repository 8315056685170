import React, {useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Home from './ui/home/Home'
import './App.css';
import Navbar from './ui/reusable/Navbar'

function App() {

  const {
    isAuthenticated,
    isLoading
  } = useAuth0()

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  return (

    <div className="App">
      <Navbar
        isAuthenticated={isAuthenticated}
        onLogoutPressed={() => {}}
        selectedTimezone={timezone}
        onTimezoneChanged={(timezoneValue) => {
          setTimezone(timezoneValue)
        }}
      />
      <div className="login">
        {isLoading &&
          <p>Loading...</p>
        }
        {!isLoading && isAuthenticated &&
          <Home timezone={timezone} />
        }
      </div>
    </div>
  );
}

export default App;
