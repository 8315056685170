import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import styles from './SrmUploadTable.module.scss'

import {
  Spinner
} from 'react-bootstrap'

import {
  SrmUpload
} from '../../../models/Srm'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

interface Props {
  uploads: SrmUpload[]
  onLoadMore: () => void
  loading: boolean
  timezone: string
}

interface RowProps {
  upload: SrmUpload
  style: React.CSSProperties
  timezone: string
}

const UploadRow = (props: RowProps) => {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow className={classes.root} style={props.style}>
        <TableCell align="center">
        {props.upload.sensors && props.upload.sensors.length > 0 &&
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        }
        </TableCell>
        <TableCell align="center">{props.upload.date.toLocaleString('en-US', { timeZone: props.timezone })}</TableCell>
        <TableCell align="center">
          {props.upload.sensors &&
            `${props.upload.sensors.length} sensor${props.upload.sensors.length > 1 ? 's' : ''}`
          }
          {!props.upload.sensors &&
            '0 sensors'
          }
        </TableCell>
        <TableCell align="center">{props.upload.signalQuality()}</TableCell>
        <TableCell align="center">{props.upload.rsrp}</TableCell>
        <TableCell align="center">{props.upload.rsrq}</TableCell>
        <TableCell align="center">{props.upload.battery}</TableCell>
      </TableRow>
      {props.upload.sensors &&
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box ml={16} mb={2}>
                <Table size="small" aria-label="sensor-uploads">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sensor Serial Number</TableCell>
                      <TableCell>Sample Range Start</TableCell>
                      <TableCell>Sample Range End</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   {props.upload.sensors.map((sensorUpload) => {
                        return(<TableRow key={sensorUpload.id}>
                          <TableCell>
                            {sensorUpload.serialNumber}
                          </TableCell>
                          <TableCell>{sensorUpload.sampleRangeStart.toLocaleString('en-US', { timeZone: props.timezone })}</TableCell>
                          <TableCell>{sensorUpload.sampleRangeEnd.toLocaleString('en-US', { timeZone: props.timezone })}</TableCell>
                        </TableRow>
                        )
                   })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </React.Fragment>
  )
}

const SrmUploadTable = (props: Props) => {
  if (!props.uploads) {
    return null
  }
  return (
    <div className={styles.content}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center"><b>Date</b></TableCell>
              <TableCell align="center"><b>Sensors</b></TableCell>
              <TableCell align="center"><b>Signal</b></TableCell>
              <TableCell align="center"><b>RSRP</b></TableCell>
              <TableCell align="center"><b>RSRQ</b></TableCell>
              <TableCell align="center"><b>Battery</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.uploads.map((upload, index) => (
              <UploadRow timezone={props.timezone} upload={upload} key={upload.id} style={ index % 2? { background : "#efefef" }:{ background : "white" }} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
              {props.loading &&
                <button onClick={props.onLoadMore} className={styles.footer} disabled>
                  <Spinner animation="border" role="status" variant="secondary" size="sm" />
                </button>
              }

              {!props.loading &&
                <button onClick={props.onLoadMore} className={styles.footer}>
                  Load more...
                </button>
              }
              </TableCell>
            </TableRow>
          </TableFooter>
         </Table>
       </TableContainer>
      </div>
  )
}

export default SrmUploadTable