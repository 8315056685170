import { JsonCodable, JsonData, JsonObject } from '../helpers'

export const utcDate = (date: Date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
}

export class Srm implements JsonCodable {
  public id: number
  public type: string
  public serialNumber: string
  public imei: string | null
  public iccid: string | null
  public date: Date

  public encode = () => {
    return {
      id: this.id,
      type: this.type,
      serialNumber: this.serialNumber,
      imei: this.imei,
      iccid: this.iccid,
      date: this.date
    }
  }

  public decode = (data: JsonData) => {
    const objectData = data as JsonObject
    this.id = objectData['id']
    this.serialNumber = objectData['serialNumber']
    this.date = new Date(objectData['date'])
    this.imei = objectData['imei']
    this.iccid = objectData['iccid']
    this.type = objectData['type']
  }
}

export class SrmUpload implements JsonCodable {
  public id: number
  public battery: number
  public rsrp: number
  public rsrq: number
  public date: Date
  public sensors: SensorUpload[]

  public encode = () => {
    return {
      id: this.id,
      battery: this.battery,
      rsrp: this.rsrp,
      rsrq: this.rsrq,
      date: this.date,
      sensors: this.sensors.map((upload) => upload.encode)
    }
  }

  public decode = (data: JsonData) => {
    const objectData = data as JsonObject
    this.id = objectData['id']
    this.battery = objectData['battery']
    this.rsrp = objectData['rsrp']
    this.rsrq = objectData['rsrq']
    let date = new Date(objectData['date'])
    this.date = utcDate(date)
    if (objectData['sensors']) {
      this.sensors = objectData['sensors'].map((sensorData: JsonObject) => {
        const sensorUpload = new SensorUpload()
        sensorUpload.decode(sensorData)
        return sensorUpload
      })
    }
  }

  public signalQuality = (): string => {
    if (this.rsrp <= 16 || this.rsrq <= 5) {
      return "Extremely Poor"
    } else if (this.rsrp <= 33 && this.rsrq <= 10) {
       return "Extremely Poor"
    } else if ((this.rsrp <= 49 && this.rsrq <= 10) || (this.rsrp <= 33 && this.rsrq <= 16)) {
      return "Poor"
    } else if ((this.rsrp <= 65 && this.rsrq <= 10) || (this.rsrp <= 49 && this.rsrq <= 16) || (this.rsrp <= 33 && this.rsrq <= 22)) {
      return "Normal"
    } else {
      return "Good"
    }

  }

}

export class SensorUpload implements JsonCodable {
  public id: number
  public serialNumber: string
  public sampleRangeStart: Date
  public sampleRangeEnd: Date

  public encode = () => {
    return {
      id: this.id,
      serialNumber: this.serialNumber,
      sampleRangeEnd: this.sampleRangeEnd,
      sampleRangeStart: this.sampleRangeStart
    }
  }

  public decode = (data: JsonData) => {
    const objectData = data as JsonObject
    this.serialNumber = objectData['serial']
    this.id = objectData['id']
    let start = new Date(objectData['sample_range_start'])
    let end = new Date(objectData['sample_range_end'])
    this.sampleRangeStart = utcDate(start)
    this.sampleRangeEnd = utcDate(end)
  }
}