import {
  Srm,
  SrmUpload
} from '../models'
import {
  JsonObject
} from '../helpers'

import axios, { AxiosResponse } from 'axios'

const host = process.env.REACT_APP_API_HOST

export class APIError extends Error {

}

export const getSrms = (matching: string, token: string): Promise<Srm[]> => {
  let client = axios.create({
    baseURL: host,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })

  return client.get('api/v1/admin/srms', {

  }).then((response: AxiosResponse) => {
    return response.data.map((srmData: JsonObject) => {
      let srm = new Srm()
      srm.decode(srmData)
      return srm
    })
  })
}

export const getUploads = (srm: Srm, token: string, count: number = 50, offset: number = 0): Promise<SrmUpload[]> => {
  let client = axios.create({
    baseURL: host,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })

  return client.get(`api/v1/admin/srms/${srm.id}/uploads?count=${count}&offset=${offset}`, {}).then((response: AxiosResponse) => {
    return response.data.map((uploadData: JsonObject) => {
      let upload = new SrmUpload()
      upload.decode(uploadData)
      return upload
    }).sort((a: SrmUpload, b: SrmUpload) => {
      if (a.date > b.date) {
        return -1
      } else {
        return 1
      }
    })
  })
}

export type LastATTSessionInfo = {
  iccid: string
  ipAddress: string
  dateSessionStarted: string
  dateSessionEnded: string
}

export const getLastSession = (iccid: string, token: string): Promise<LastATTSessionInfo> => {
  let client = axios.create({
    baseURL: host,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
  })
  return client.get(`api/v1/admin/att_session?iccid=${iccid}`, {}).then((response: AxiosResponse) => {
    return response.data
  })
}