import React from 'react'
import {Srm} from '../../../models/Srm'
import styles from './SrmRow.module.scss'

interface Props {
  srm: Srm
  selected: boolean
  timezone: string
}

const SrmRow = (props: Props) => {
  return (
    <div className={[styles.content, props.selected ? styles.selected : ""].join(" ")}>
      <p className={styles.title}>
        {props.srm.serialNumber}
      </p>
      <p className={styles.subtitle}>
        {props.srm.date.toLocaleString('en-US', { timeZone: props.timezone })}
      </p>
    </div>
  )
}

export default SrmRow