import React from 'react'
import styles from './KeyValueLabel.module.scss'

interface Props {
  keyText: string
  valueText: string
}

const KeyValueLabel = (props: Props) => {
  return (
    <div className={styles.content}>
      <p className={styles.key}>
        {props.keyText}:
      </p>
      <p className={styles.value}>
        {props.valueText}
      </p>
    </div>
  )
}

export default KeyValueLabel