import React, { useState } from 'react'
import SrmList from './SrmList'
import styles from './Home.module.scss'
import SrmDetails from './SrmDetails'
import { Srm } from '../../models/Srm'

interface Props {
  timezone: string
}

const Home = (props: Props) => {
  const [selectedSrm, setSelectedSrm] = useState<Srm | null>(null)

  return (
    <div className={styles.content}>
      <div id={styles.sidenav}>
        <SrmList timezone={props.timezone} onSrmSelect={(srm: Srm) => {
          setSelectedSrm(srm)
        }} />
      </div>
      <div id={styles.details}>
        <SrmDetails timezone={props.timezone} srm={selectedSrm} />
      </div>
    </div>
  )
}

export default Home