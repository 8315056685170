
const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const configJson = {
  "domain": domain,
  "clientId": clientId,
  "audience": "",
  "scope": "openid userid profile"
}

export function getConfig() {
  const audience =
    configJson['audience'] && configJson['audience'] !== "YOUR_API_IDENTIFIER"
      ? configJson['audience']
      : null;

  return {
    domain: configJson['domain'],
    clientId: configJson['clientId'],
    ...(audience ? { audience } : null),
  };
}