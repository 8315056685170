import React, { useEffect, useState } from 'react'
import styles from './SrmDetails.module.scss'
import {
  Srm,
  utcDate,
  SrmUpload
} from '../../models/Srm'
import {useAuth0} from '@auth0/auth0-react'
import {
  getUploads,
  getLastSession
} from '../../api/client'

import {
  Spinner
} from 'react-bootstrap'


import KeyValueLabel from '../reusable/KeyValueLabel'
import SrmUploadTable from './components/SrmUploadTable'

interface Props {
  srm?: Srm,
  timezone: string
}

type Page = {
  count: number
  offset: number
}

const SrmDetails = (props: Props) => {
  const { getIdTokenClaims } = useAuth0()
  const [srmUploads, setSrmUploads] = useState<SrmUpload[] | null>(null)

  const [lastATTSessionDate, setLastATTSessionDate] = useState<Date | null>(null)

  const [page, setPage] = useState<Page>({count: 50, offset: 0})

  const [loading, setLoading] = useState<boolean>(true)

  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  const loadNextPage = () => {
    (async () => {
      try {
        const claims = await getIdTokenClaims();
        setLoadingMore(true)
        const nextPage = page.offset + 1
        getUploads(props.srm, claims.__raw, page.count, nextPage * page.count).then((uploads: SrmUpload[]) => {
          setLoadingMore(false)
          setPage({count: page.count, offset: nextPage})
          setSrmUploads(srmUploads.concat(uploads))
        })
      } catch (error) {
        console.error(error)
      }
    })()
  }

  useEffect(() => {
    if (!props.srm) {
      return
    }
    (async () => {
      try {
        const claims = await getIdTokenClaims();
        setPage({count: 50, offset: 0})
        setLoading(true)
        getUploads(props.srm, claims.__raw, 50, 0).then((uploads: SrmUpload[]) => {
          setLoading(false)
          setSrmUploads(uploads)
        })
      } catch (error) {
        console.error(error)
      }
    })()
  }, [props.srm, getIdTokenClaims])
  
  useEffect(() => {
    if (!props.srm) {
      return
    }
    (async () => {
      try {
        const claims = await getIdTokenClaims();
        setLastATTSessionDate(null)
        getLastSession(props.srm.iccid, claims.__raw).then((response) => {
          var date = response.dateSessionStarted
          let split = date.split(' ')
          let parsedDate = new Date(split[0] + 'T' + split[1].split("+")[0])
          let asUTC = utcDate(parsedDate)
          setLastATTSessionDate(asUTC)
        })
      } catch (error) {
        console.error(error)
      }
    })()
  }, [props.srm, getIdTokenClaims])

  if (!props.srm) {
    return (
      <div className={styles.content}>
        <p className={styles.selectText}>
          Select an SRM to view details
        </p>
      </div>
    )
  }
  return (
    <div className={styles.content}>
      {loading === true &&
        <div className={styles.loading}>
          <p>Loading...</p>
          <Spinner animation="border" role="status" variant="secondary" />
        </div>
      }
      {loading === false && props.srm &&
        <div>
          <h1>SRM Info</h1>
          {srmUploads && srmUploads.length > 0 &&
            <ul>
              <li>
                <KeyValueLabel
                  keyText="Serial Number"
                  valueText={props.srm.serialNumber}
                />
              </li>
              <li>
                <KeyValueLabel
                  keyText="Last Seen (Sync Server)"
                  valueText={srmUploads[0].date.toLocaleString('en-us', { timeZone: props.timezone })}
                />
              </li>
              <li>
                <KeyValueLabel
                  keyText="Cell Connection"
                  valueText={srmUploads[0].signalQuality()}
                />
              </li>
              <li>
                <KeyValueLabel
                  keyText="ICCID"
                  valueText={props.srm.iccid}
                />
              </li>
              <li>
                <KeyValueLabel
                  keyText="IMEI"
                  valueText={props.srm.imei}
                />
              </li>
              <li>
                <KeyValueLabel
                  keyText="Last Session Date (AT&T)"
                  valueText={lastATTSessionDate === null ? "Loading..." : lastATTSessionDate.toLocaleString("en-us", {timeZone: props.timezone})}
                />
              </li>
            </ul>
          }
          <SrmUploadTable
            timezone={props.timezone}
            uploads={srmUploads}
            onLoadMore={loadNextPage}
            loading={loadingMore}
          />
        </div>
      }
    </div>
  )
}

export default SrmDetails